<template>
  <v-container
    fluid
    style="padding: 0"
  >
    <v-card
      class="white py-6 px-6 mt-3 project-detail-height"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <div class="d-flex align-center">
        <v-icon
          color="blue"
          @click="back"
        >
          mdi-chevron-left
        </v-icon>
        <v-btn
          text
          color="blue"
          class="font-weight-bold text-capitalize"
          @click="back"
        >
          {{
            $t('projects.create_project.back_to_projects')
          }}
        </v-btn>
      </div>
      <div class="d-flex align-center justify-space-between text-start mt-7">
        <div class="d-flex align-center">
          <div style="position: relative; display: inline-block">
            <v-avatar size="70">
              <v-img
                :src="imageSrc ?? selectedProject?.avatarUrl"
                :lazy-src="require('@/assets/png/project.png')"
                width="100%"
                alt="logo"
              />
            </v-avatar>
            <v-btn 
              icon
              class="position-absolute right-0 bottom-0 camera-placeholder"
              @click="openFileDialog"
            >
              <CameraIcon />
              <input
                ref="fileInput"
                type="file"
                :accept="`${projectImageTypes.join(', ')}`"
                style="display: none"
                @change="handleFileChange"
              >
            </v-btn>
          </div>

          <template v-if="!editTitle">
            <div
              class="ml-4"
              @mouseenter="visibleTitle = true"
              @mouseleave="visibleTitle = false"
            >
              <div class="font-weight-bold fs-24px text-theme-base d-flex align-center">
                {{ selectedProject.name }}
                <PencilIcon
                  v-if="visibleTitle"
                  class="ml-3"
                  role="button"
                  @click="handleClickEditTag"
                />
              </div>
              <div class="fs-14px text-theme-secondary font-weight-regular">
                {{ selectedProject?.customFields?.description }}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="ml-4">
              <div class="font-weight-bold fs-24px text-theme-base d-flex align-center">
                <v-text-field
                  v-model="projectTitle"
                  dense
                  class="font-weight-bold fs-24px text-theme-base edit-title-input"
                  hide-details
                />
              </div>
              <div class="fs-14px text-theme-secondary font-weight-regular edit-subtitle-input">
                <v-text-field
                  v-model="projectSubTitle"
                  dense
                  height="16px"
                  class="fs-14px text-theme-secondary font-weight-regular"
                  hide-details
                />
              </div>
            </div>
          </template>
        </div>
        <div class="d-flex align-center">
          <div class="fs-14px text-theme-secondary font-weight-regular mr-2">
            {{ $t('projectKey') + ':' }} 
          </div>
          <span class="fs-16px fw-semibold">
            {{ selectedProject.key }}
          </span>
        </div>
      </div>
      <v-card
        class="mt-3"
        rounded="lg"
        elevation="0"
        width="100%"
      >
        <div
          class="d-flex align-center justify-space-between"
        >
          <div
            class="d-flex align-center"
          >
            <SearchComponent
              class="mr-3"
              @update:search="setSearchFilter"
            />
            <ProjectUserFilter 
              :available-roles="roles"
              :available-tags="tags"
              :available-projects="projectsData"
            />
          </div>
          <div
            class="d-flex align-center justify-end"
          >
            <SettingsMenu 
              table-type="projectDetails" 
            />
          </div>
        </div>
        <Table
          :filtered-headers="filteredHeaders"
          :items="activeMembersHasData ? filteredItem : []"
          :item-key="itemKey"
          :tags="tags"
          :roles="roles"
          :projects="projectsData"
        />
      </v-card>
      <!--
      <div class="mt-6 d-flex">
        <v-chip
          @click="all()"
          :class="{ 'blue--text': filter === 'all' }"
          width="200px"
          :color="filter === 'all' ? 'blue-light' : 'gray-light'"
          label
        >
          <div class="font-weight-bold px-2">
            {{ $t('all') }} <span class="ml-2">{{ allCount }}</span>
          </div>
        </v-chip>
        <div class="ml-2">
          <v-chip
            @click="select()"
            :class="{ 'blue--text': filter === 'selected' }"
            width="200px"
            :color="filter === 'selected' ? 'blue-light' : 'gray-light'"
            label
          >
            <div class="font-weight-bold px-2">
              {{ $t('selected') }} <span class="ml-2">{{ selectedCount }}</span>
            </div>
          </v-chip>
        </div>
      </div>
      <div class="d-flex align-center justify-space-between mt-5">
        <div class="d-flex align-center">
          <SearchComponent :search="search" @update:search="search = $event" :placeholder="placeholder"></SearchComponent>
          <ProjectItemFilter
            :selectedRoles="selectedRoles"
            :selectedTags="selectedTags"
            @selectedRoles="handleSelectedRoles"
            @selectedTags="handleSelectedTags"
          />
        </div>
        <div class="d-flex align-center">
          <v-sheet
            color="gray-100"
            width="50px"
            class="d-flex ml-4 align-center justify-center"
            height="50px"
            rounded="lg"
            v-on="on"
          >
            <v-icon color="gray-ish">mdi-cog-outline</v-icon>
          </v-sheet>
        </div>
      </div>
      <div>
        <v-row class="mt-3">
          <v-chip
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            v-for="(role, index) in selectedRoles"
            :key="index"
            label
          >
            {{ $t('role') }}: {{ role }}
          </v-chip>
          <v-chip
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            v-for="(tag, index) in selectedTags"
            :key="index"
            label
          >
            {{ $t('tag') }}: {{ tag }}
          </v-chip>
          <v-btn
            class="text-capitalize mt-1"
            color="blue"
            text
            v-if="selectedTags.length > 0 || selectedRoles.length > 0"
            @click="clearAll"
            >{{ $t('clearAll') }}</v-btn
          >
        </v-row>
      </div>
      <v-data-table
        :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
        class="custom-table mt-6"
        :headers="headers"
        :items="filteredUsers"
        :item-key="itemKey"
        :class="tableClass"
      >
        <template v-slot:[`header.name`]="{ header }">
          <div class="d-flex align-center">
            <div class="custom-div mr-2"></div>
            <span class="header_text">{{ header.text }}</span>
            <v-icon size="18" color="gray-ish">mdi-chevron-down</v-icon>
          </div>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <td class="d-flex align-center">
            <div class="custom-div mr-2"></div>
            <v-avatar class="mr-2" size="40">
              <img :src="item.image" width="100%" alt="logo" />
            </v-avatar>
            <div class="text-start">
              <div class="text-subtitle-2 font-weight-bold">{{ item.name }}</div>
            </div>
          </td>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <td class="d-flex justify-space-between align-center">
            <span>{{ item.email }}</span>
            <v-btn icon>
              <v-icon color="gray-ish" @click="selectItem(item)">mdi-plus-box-outline</v-icon>
            </v-btn>
          </td>
        </template>
        <template v-slot:[`item.role`]="{ item }">
          <td class="text-start">
            <v-menu offset-y :position-y="10">
              <template #activator="{ on }">
                <div v-on="on" role="button">
                  {{ item.role }}
                  <v-icon class="ml-3">mdi-chevron-down</v-icon>
                </div>
              </template>
              <v-list>
                <v-list-item @click="handleUpdateRole(role, item.id)" v-for="role in defaultRoles" :key="role">
                  <v-icon>mdi-building</v-icon>
                  {{ role }}
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </template>
        <template v-slot:[`item.tag`]="{ item }">
          <td class="text-start">
            <v-menu offset-y :position-y="10">
              <template #activator="{ on }">
                <div v-on="on" role="button">
                  {{ `${item.tag[0]} +${item.tag.length - 1}` }}
                  <v-icon class="ml-3">mdi-chevron-down</v-icon>
                </div>
              </template>
              <v-list class="pt-4">
                <v-list-item v-for="tag in item.tag" :key="tag" class="m-0">
                  <div class="d-flex align-start">
                    <v-checkbox dense v-model="checkbox" class="mb-0" />
                    <div class="d-flex align-center mt-2">
                      <span class="mr-2">{{ tag }}</span>
                      <v-icon class="mr-2" size="16" @click="handleEditTag(tag)">mdi-pencil-outline</v-icon>
                      <DeleteIcon />
                    </div>
                  </div>
                </v-list-item>
                <v-list-item @click="handleCreateTag()">
                  <v-icon class="mr-2" color="blue">mdi-plus</v-icon>
                  <span class="blue--text" role="button">{{ $t('createTag') }}</span>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </template>
      </v-data-table>
      -->

      <div class="d-flex justify-end btn-table-action">
        <v-btn
          color="gray-100"
          width="150px"
          class="mr-4 text-capitalize"
          elevation="0"
          @click="onProjectCancel"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="blue"
          dark
          width="150px"
          elevation="0"
          class="text-capitalize"
          @click="onProjectSave"
        >
          {{ $t('save') }}
        </v-btn>
      </div>
    </v-card>
    <v-dialog
      v-model="drawer"
      fullscreen
    >
      <v-card class="pa-4">
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-space-between align-center"
            >
              <h5 class="font-weight-bold">
                {{ !title ? $t('tagCreationHeader') : $t('tagEditHeader') }}
              </h5>
              <v-btn
                icon
                @click="drawer = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="title"
                :label="$t('titleLabel')"
                filled
                :placeholder="$t('enterTitle')"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="description"
                :label="$t('descriptionLabel')"
                filled
                :placeholder="$t('enterDescription')"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="type"
                :label="$t('typeLabel')"
                filled
                :placeholder="$t('enterType')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="gray-100"
                full-width
                class="w-full text-capitalize"
                elevation="0"
                @click="drawer = false"
              >
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="primary"
                class="w-full text-capitalize"
                depressed
                @click="onTagSave"
              >
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { items, users } from '@/constants/data.js';
import { createNamespacedHelpers, mapGetters, mapActions as projectMapActions } from 'vuex';
import Table from '@/components/Project/ProjectViewUserTable.vue';
import SearchComponent from '@/components/Project/SearchComponent.vue';
import ProjectUserFilter from '@/components/Project/ProjectUserFilter.vue';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import makeOrgService from '@/services/api/org';
import makeTagService from '@/services/api/tag';
import makeProjectService from '@/services/api/project';
import makeRoleService from '@/services/api/role';
import { showErrorToast } from '@/utils/toast';
import CameraIcon from '@/assets/svg/camera.svg';
import PencilIcon from '@/assets/svg/pencil.svg';
import { projectImageTypes } from '@/constants/fileTypes.js';
import fileValidator from '@/mixins/fileValidator.js';

const { mapState: mapProjectState, mapActions } = createNamespacedHelpers('project');
const { mapState: mapUserState } = createNamespacedHelpers('user');

export default {
  name: 'ProjectDetailView',
  components: {
    Table,
    SearchComponent,
    ProjectUserFilter,
    SettingsMenu,
    CameraIcon,
    PencilIcon,
  },
  mixins: [ fileValidator ],
  data() {
    return {
      projectImageTypes,
      file: null,
      imageSrc: null,
      placeholder: 'Search by name, email, etc',
      selectedRoles: [],
      selectedTags: [],
      search: '',
      items: items,
      itemKey: 'uid',
      users: users,
      options: ['all', 'selected'],
      project: null,
      headers: [],
      tableClass: '',
      checkbox: false,
      drawer: false,
      title: '',
      description: '',
      type: '',
      visibleTitle: false,
      editTitle: false,
      projectTitle: '',
      projectSubTitle: '',
      selectedProject: {},
      searchFilter: '',
      filter: 'active',
      activeUserCount: 0,
      activeMembers: [],
      tags: [],
      projectsData: [],
      roles: [],
      handle: this.$route.params.handle,
    };
  },
  async mounted() {
    await this.init(this.handle);
  },
  async created() {
    if(!this.dynamicHeaders.projectDetails) {
      this.initializeHeaders({ type: 'projectDetails' });
    }
    this.headers = this.dynamicHeaders.projectDetails;
    if (this.projects.length < 1) {
      await this.get(this.$route.params.handle)
    }
  },
  methods: {
    ...mapActions(['get', 'update']),
    ...projectMapActions({
      initializeHeaders: 'headers/initializeHeaders',
      uploadToServer: 'attachment/uploadToServer',
    }),
    back() {
      this.$router.push({
        name: 'ProjectsView'
      });
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const files = Array.from(event.target.files);

      const validationResult = this.validateMimeTypes(files, projectImageTypes);

      if (!validationResult.valid) {
        showErrorToast(this.$swal, this.$t('error.fileFormatNotSupported'));
      } else {
        const file = event.target.files[0];

        this.previewImage(file);
      }
    },
    async previewImage(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
        this.file = file;

        if(this.selectedProject.uid && this.file){
            const handle = this.$route.params.handle;
            const mediaType = 'attachment'
            const relatedToUid = this.selectedProject.uid;
            const relatedTo = 'project';
            await this.uploadToServer({handle, relatedTo, relatedToUid, mediaType, file: this.file})
        }
      }
    },
    async getMembers(per_page, next_page, handle) {
      const orgService = makeOrgService(this.$api);
      try {
        const response = await orgService.getUsers(handle, { per_page: per_page, next_page: next_page });
        this.activeMembers = response.data.users;
        if (next_page == 0) {
          let membersCount = response.data.totalMembers;
          this.activeUserCount = membersCount;
        }

      } catch (err) {
        console.log(err)
      }
    },
    all() {
      this.filter = 'all';
    },
    select() {
      this.filter = 'selected';
    },
    handleSelectedRoles(roles) {
      this.selectedRoles = roles;
    },
    handleSelectedTags(tags) {
      this.selectedTags = tags;
    },
    setSearchFilter(searchText) {
      this.searchFilter = searchText;
    },
    handleUpdateRole(role, userID) {
      let _users = [...this.users];
      this.users = _users.map((user) => {
        if (user.id === userID) {
          return {
            ...user,
            role,
          };
        } else return user;
      });
    },
    handleClickEditTag() {
      this.editTitle = true;
      this.projectSubTitle = this.selectedProject.customFields.description;
      this.projectTitle = this.selectedProject.name;
    },
    handleCreateTag() {
      this.title = '';
      this.description = '';
      this.type = '';
      this.drawer = !this.drawer;
    },
    handleEditTag(tag) {
      this.title = tag;
      this.description = '';
      this.type = '';
      this.drawer = !this.drawer;
    },
    clearAll() {
      this.selectedRoles = [];
      this.selectedTags = [];
    },
    onProjectCancel() {
      this.users.forEach((user) => {
        user.selected = false;
      });
      this.$router.go(-1);
    },
    onProjectSave() {
      const payload = {
        customFields: {
          ...this.selectedProject.customFields,
          description: this.projectSubTitle,
        },
        name: this.projectTitle,
      };
      this.update({
        swal: this.$swal,
        handle: this.$route.params.handle,
        oldProject: this.selectedProject,
        payload,
      });

    },
    onTagSave() { }, // TODO
    selectItem(item) {
      item.selected = true;
    },
    async init(handle) { 
      try {
        await Promise.all([this.getTags(handle), this.getRoles(handle), this.getProjects({}, handle)]);
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        this.loading = false;
      }
      await this.getMembers(30, 0, handle);
    },
    async getTags() {
      const handle = this.$route.params.handle;
            const tagService = makeTagService(this.$api);
            try {
                const response = await tagService.getTags(handle);
                this.tags = response.data
            } catch (err) {
              showErrorToast(this.$swal, 'fetchError', { item: 'tags' });
              return [];
            }
    }, 
    async getProjects(additionalParams = {}, handle) {
      if (!handle) handle = this.handle;
      const searchParams = new URLSearchParams();
      const projectService = makeProjectService(this.$api);
      searchParams.set('status', this.filter);
      searchParams.set('includeCount', true);
    
      Object.keys(additionalParams).forEach(key => {
        searchParams.set(key, additionalParams[key]);
      });

      this.loading = true;
      try {
        const response = await projectService.getProjects(handle, searchParams.toString());
        this.projectsData = response.data.projects;
        this.selectedProject = response.data.projects.find((project) => project.key === this.$route.params.key);
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'projects' });
        return [];
      } 
    },
    async getRoles(handle) {
      const roleService = makeRoleService(this.$api);
      try {
        const response = await roleService.getRoles(handle);
        this.roles = response.data?.roles;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'roles' });
      }

    },
  },
  computed: {
    ...mapProjectState(['projects']),
    ...mapUserState(['currentAccount']),
    ...mapGetters({
      dynamicHeaders: 'headers/dynamicHeaders'
    }),
    paramID() {
      return this.$route.params.id;
    },
    customCheckboxClass() {
      return {
        'custom-checkbox': true,
      };
    },
    activeMembersHasData() {
      return this.activeMembers.length > 0;
    },
    allCount() {
      return this.users.length;
    },
    selectedCount() {
      return this.users.filter((item) => item.selected === true).length;
    },
    filteredItem() {
      let filtered =
        this.filter === 'active'
          ? this.activeMembers
          : this.filter === 'pending'
            ? this.pendingInvites
            : this.requestedUsers;

      if (this.searchFilter.length > 0) filtered = filtered.filter((item) => this.matchesFilter(item));

      return filtered;
    },
    filteredUsers() {
      let filtered = this.users;

      if (this.filter === 'all') {
        filtered = this.users;
      } else if (this.filter === 'selected') {
        filtered = filtered.filter((item) => item.selected === true);
      }

      if (this.search) {
        const searchTerm = this.search.toLowerCase();
        filtered = filtered.filter((item) => {
          return item.name.toLowerCase().includes(searchTerm);
        });
      }

      if (this.selectedTags.length > 0) {
        filtered = filtered.filter((user) => {
          return this.selectedTags.every((tag) => user.tag.includes(tag));
        });
      }

      if (this.selectedRoles.length > 0) {
        filtered = filtered.filter((user) => {
          return this.selectedRoles.every((role) => user.role.includes(role));
        });
      }

      return filtered;
    },
    filteredHeaders() {
      const filtered = this.filteredMenuHeaders.filter((header) => header.checked);
      return filtered;
    },
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.text != 'Actions');
      return filtered;
    },
  },
  async beforeRouteUpdate(to, from, next) {
    const handle = to.params.handle;
    if (handle && handle !== from.params.handle) {
      try {
        await this.init(handle);
        next();
      } catch (error) {
        next();
      }
    } else {
      console.log("not run")
      next();
    }
  },
};
</script>

<style scoped>
.project-detail-height {
    min-height: calc(100vh - 24px - 6.25rem);
    height: 100%;
}
.custom-checkbox .v-input--selection-controls .v-input--checkbox .v-input__control .v-icon {
  border: none !important;
}

.custom-checkbox input[type='checkbox'] {
  border-radius: 20px;
  width: 16px;
  height: 16px;
}

.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-expansion-panel-head__wrap {
  padding: 0 !important;
}

.custom-div {
  height: 15px;
  width: 15px;
  background-color: #eaecf0;
  border-radius: 4px;
}
.btn-table-action {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1rem 1.5rem;
}
</style>
